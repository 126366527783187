.custom-toast {
  position: absolute;
  top: 60px;
  /* left: 55%; */
  font-size: large;
  color: black;
}
.custom-toast1 {
  /* background: #00BF91 !important; */
  /* height: 100% !important; */
  position: absolute;
  top: 160px;
  /* left: 55%; */
  font-size: large;
  color: black;
}
